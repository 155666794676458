import moment from 'moment-timezone';
import { useEffect } from 'react';
import { getTimezoneName } from './timezones'

import { getUrlParameter } from 'utils/urls';

const ArchCalendarBookingSelectDay = () => {


    return (
        <div className="archk-calendar-wrapper">
                        
            <div className="archk-calendar-header">
                <h2 className="mb-4 display-3"><i className="fas fa-check-double text-white mr-2 " /> Transfer Has Been Confirmed</h2>
            </div>
    
            <div className="archk-calendar-body">
                <p className="text-white mb-0">You may now transfer this lead to Phillips Law <b className='text-warning font-weight-bold'>At Their Dedicated DID</b></p>
            </div>
    
        </div>
    )
}

export default ArchCalendarBookingSelectDay;