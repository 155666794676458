
import apiWorker from '../apiWorker'

const nylas = {

    getCalendar: (times)  => apiWorker('post',  `/v1/integrations/nylas/calendar`, times),
    createEvent: (data)  => apiWorker('post',  `/v1/integrations/nylas/events`, data),
    getConsultation: (consultation_id)  => apiWorker('get',  `/v1/integrations/nylas/events/${consultation_id}`),
    finishConsultation: (consultation_id, data)  => apiWorker('patch',  `/v1/integrations/nylas/events/${consultation_id}`, data),
    
    getLead: (lead_id)  => apiWorker('get',  `/v1/integrations/nylas/leads/${lead_id}`),
    createLead: (data)  => apiWorker('post',  `/v1/integrations/nylas/leads`, data),
    startFromLink: (_id)  => apiWorker('get',  `/v1/integrations/nylas/event_start/${_id}`),
    
    review: (data)  => apiWorker('post',  `/v1/integrations/nylas/review`, data),
    
    createCaseOnLead: (data)  => apiWorker('post',  `/v1/integrations/nylas/create_case_on_lead`, data),
    
    live: {
        getLiveConsultation: (contact)  => apiWorker('get',  `/v1/integrations/nylas/live/getConsult/${contact}`),
        createLead: (data)  => apiWorker('post',  `/v1/integrations/nylas/live/createLead`, data),
        createPhillipsLead: (data)  => apiWorker('post',  `/v1/integrations/nylas/create_phillips_lead`, data),
        createConsultation: (data)  => apiWorker('post',  `/v1/integrations/nylas/live/createConsultation`, data),
        findNext: (user, _id)  => apiWorker('get',  `/v1/integrations/nylas/live/liveFindNext/${user}${_id ? '?_id=' + _id : ''}`),
        poll: (queue_id)  => apiWorker('get',  `/v1/integrations/nylas/live/poll/${queue_id}`),
    }

}

export default nylas;