const timezones = [
    { code: 'America/Adak',         name: 'HST (Hawaii TIme)' },
    { code: 'America/Anchorage',    name: 'AKST (Alaska Time)' },
    { code: 'America/Los_Angeles',  name: 'PDT (Pacific Time)' },
    { code: 'America/Denver',       name: 'MDT (Mountain Time)' },
    { code: 'America/Chicago',      name: 'CDT (Central Time)' },
    { code: 'America/New_York',     name: 'EDT (Eastern Time)' },
]

export const getTimezoneName = (tz) => {
    if(!tz) return tz;
    const found = timezones.find(t => t.code === tz);
    if(found) return `Timezone: ${found.name}`;
    return `Timezone: ${tz.replace(/_/g, ' ')}`
}

export default timezones;