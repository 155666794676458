import { useEffect, useCallback, useState } from 'react';
import moment from 'moment-timezone';

import { getTimezoneName } from './timezones'
import _nylas from '_functions/nylas';

import Dots from 'components/markup/loading/Dots'
import { toggleAlertBS } from 'store/functions/system/system';

import { getUrlParameter } from 'utils/urls'

const ArchCalendarBookingSelectTime = ({
    onSelectTime, 
    titleSelectTime, 
    setView, 
    timezone, 
    calendarTimezone, 
    bookingInterval, 
    selectedDate, 
    calendarStartTime, 
    calendarEndTime,
    companySize,
    mainColor
}) => {

    const [times, setTimes] = useState(null)
    const [now] = useState(Math.floor(new Date() / 1000))

    const fetchCalendar = useCallback(async (_times) => {
        const data = await _nylas.getCalendar({
            user: getUrlParameter('u'),
            times: _times, 
            companySize,
            date: selectedDate.date,
            calendarTimezone,
            calendarStartTime,
            calendarEndTime,
            timezone,
            bookingInterval,
        });
        if(data.data) {
            setTimes(data.data)
        } else {
            setView('select day')
            toggleAlertBS(true, 'Something went wrong getting information for this day, please try again.')
        }
    }, [companySize, setView, calendarTimezone, timezone, bookingInterval, calendarStartTime, calendarEndTime, selectedDate])

    useEffect(() => {
        fetchCalendar()
    }, [fetchCalendar])

    return (
        <div className="archk-calendar-wrapper">
            
            <div className="archk-calendar-header pb-0">
                {times && !times.length ? (
                    <h2 style={{color: mainColor ? mainColor : '#FF8D00'}} className="mb-0 display-3"><i className="fas fa-phone-slash text-white " />  No Times Are Currently Available</h2>
                ) : (
                    <h2 style={{color: mainColor ? mainColor : '#FF8D00'}} className="mb-0 display-3"><i className="fas fa-calendar-check text-white mr-2 " /> {titleSelectTime}</h2>
                )}
            </div>

            <div className="archk-calendar-header text-cener position-relative pt-0">

                <button className="btn btn-secondary my-4" onClick={() => setView('select day')} >
                    <i className="fas fa-arrow-left mr-3 cursor-pointer" />
                    Select Another Date
                </button>

                <h3 className="mb-0"> <b className="text-underlin">{moment(selectedDate.date).tz(timezone).format('dddd')}</b></h3>
                <p className="text-sm mb-0">{moment(selectedDate.date).tz(timezone).format('MMMM Do, YYYY')}</p>

                {times && times.length ? (
                    <div className="mt-3">
                        <h3 className="text-cente mb-0">Select A Time Below:</h3>
                        <p className="text-sm mb-0 "><small>{getTimezoneName(timezone)}</small></p>
                    </div>
                ) : times ? null : (
                    <h3 className="mb-0 mt-4">Loading Available Times<Dots /></h3>
                )}
           
            </div>
    
            <div className="archk-calendar-body">
                {times && times.length ? times.map((time, i) => (
                    time.start < now ? '' :
                    <div 
                        key={i} 
                        className="archk-calendar-select-time-wrapper text-center rounded mb-4 py-3"
                        // style={{color: mainColor ? mainColor : '#FF8D00', borderColor: mainColor ? mainColor : '#FF8D00'}} 
                        onClick={() => onSelectTime(time)}
                    >
                        {time.text}
                    </div>
                )) : times ? (
                    <div>
                        <p style={{color: mainColor ? mainColor : '#FF8D00'}} className="primary cursor-pointer my-4"  onClick={() => setView('select day')}>Unfortunately, there are no available times for {moment(selectedDate.date).format('MMMM Do')}, <span className="text-underline">please click here to select another date here.</span></p>
                    </div>
                ) : (
                    <div>
                        <div className="skeletal-load text-center rounded mb-4 py-3" >9:00 AM</div>
                        <div className="skeletal-load text-center rounded mb-4 py-3" >9:30 AM</div>
                        <div className="skeletal-load text-center rounded mb-4 py-3" >10:00 AM</div>
                        <div className="skeletal-load text-center rounded mb-4 py-3" >10:30 AM</div>
                        <div className="skeletal-load text-center rounded mb-4 py-3" >11:00 AM</div>
                        <div className="skeletal-load text-center rounded mb-4 py-3" >11:30 AM</div>
                    </div>
                )}
            </div>
        </div>
    )

}

export default ArchCalendarBookingSelectTime;