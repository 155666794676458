import moment from 'moment-timezone';
import { useEffect } from 'react';
import { getTimezoneName } from './timezones'

import { getUrlParameter } from 'utils/urls';

const ArchCalendarBookingSelectDay = ({timezone, selectedTime, titleConfirmation, attorney, mainColor}) => {

    useEffect(() => {
        const r = getUrlParameter('r');
        const leadSource = getUrlParameter('l');
        if(r) {
            window.open(r);
        }

        if(leadSource === '6436c61435a858642871c135') {
            const script = document.createElement("script");

            script.src = "https://www.googletagmanager.com/gtag/js?id=AW-11147233990";
            script.async = true;
        
            document.body.appendChild(script);

            try {
                const script2 = document.createElement("script");

                script2.src = "bat.bing.com/bat.js";
                script2.async = true;
            
                document.body.appendChild(script2);
            } catch(e) {
                console.log(e)
            }

            setTimeout(() => {

                try {
                    new window.UET({
                        ti: "187040264",
                        enableAutoSpaTracking: true
                    })
                } catch(e) {
                    console.log(e)
                }
               

                window.dataLayer = window.dataLayer || [];
                function gtag(){window.dataLayer.push(arguments);}
                gtag('js', new Date());
              
                gtag('config', 'AW-11147233990');
            }, [2000])
        } else if(leadSource === '640b75abbf6f0e35eeb4a720') {
            const script = document.createElement("script");

            script.src = "https://www.googletagmanager.com/gtag/js?id=AW-11114635531";
            script.async = true;
        
            document.body.appendChild(script);

            setTimeout(() => {
                window.dataLayer = window.dataLayer || [];
                function gtag(){window.dataLayer.push(arguments);}
                gtag('js', new Date());
              
                gtag('config', 'AW-11114635531');
                gtag('event', 'conversion', {'send_to': 'AW-11114635531/3yD_CK7LmpEYEIvC77Mp'});
            }, [2000])
        }
    }, [])

    return (
        <div className="archk-calendar-wrapper">
                        
            <div className="archk-calendar-header">
                <h2 style={{color: mainColor ? mainColor : '#FF8D00'}} className="mb-4 display-3"><i className="fas fa-check-double text-white mr-2 " /> {titleConfirmation}</h2>
                <h3 className="mb-0"> Selected Time:</h3>
                <p className="mb-0 text-sm">{moment(selectedTime.date).tz(timezone).format('dddd MMMM Do - h:mm A')}  </p>
            </div>
    
            <div className="archk-calendar-body">
                <p className="text-white font-weght-bold">You should receive an email invitation shortly inviting you to an online meeting with your attorney {attorney} at your selected time.</p>
                <p className="mb-0">If you have any questions about your appointment feel free to give us a call at (850) 783-8906</p>
            </div>

            <div className="archk-calendar-footer">
                <p className="text-sm mb-0 text-white">
                    {getTimezoneName(timezone)}{' '}
                </p>
            </div>
    
        </div>
    )
}

export default ArchCalendarBookingSelectDay;