import { useState } from 'react';
import { Container, FormGroup, Input } from 'reactstrap';

import Logo from 'assets/img/brand/BizHeadLogoCut.png';

const Consult = () => {

    const [redirect, setRedirect] = useState('');

    const runRedirect = () => {
        if(redirect === 'false' || redirect === '') return;

        console.log("/live/" + redirect)
        window.location.href = "/live/" + redirect
    }

    return (
       <Container className="pt-5">

            <div style={{maxWidth: 360, margin: 'auto', }} className="z-depth-5 border rounded pb-3">
                <div className="text-centr pl-3 pt-3 ml-1">
                    <img src={Logo} style={{width: 225}} alt="Biz Head Law" />
                </div>

                <div className="archk-calendar-wrapper p-5">
                        
                        <div className="archk-calendar-header">
                            <h2 className="mb-4 display-3"><i className="fas fa-check-double text-white mr-2 " />Get Live Consult</h2>
                            <h3 className="mb-0 text-capitalize"> Select your name below:</h3>

                        </div>
                
                        <div className="archk-calendar-body mb-5">
                            <FormGroup className="mt-3">
                                <Input
                                    onChange={(e) => setRedirect(e.target.value)}
                                    type="select"
                                >
                                    <option value="false"></option>
                                    <option value="635403a394d0f0e0af329ff3">Anthony Bux</option>
                                    <option value="6334534a545669300ee60548">Chad Wold</option>
                                    <option value="640a4f65ae45476a7bc94399">Chase Loncao</option>
                                    <option value="63d913665743ce0ebc9f825e">Debbie Pickus</option>
                                    <option value="640a4eb9ae45476a7bc9432d">Hannah Delsole</option>
                                    <option value="6334539a7ee63e30202dd8df">Jeff Gorski</option>
                                    <option value="63345497404415304760ff6b">Kevin Chern</option>
                                    <option value="64011029915d9801da0085d8">Kim Youssef</option>
                                    <option value="6411e1e974cf5f2b057fa58f">Matt Bedran</option>
                                    <option value="640680cf37a3ba497c4e5cba">Matthew Miller</option>
                                    <option value="63bc5f8ca9f7553ee2c82a9b">Michael Bane</option>
                                    <option value="640b93de3796b447175d4b9d">Nick Haddock</option>
                                    <option value="633453da4c56e0302a486fae">Owen Hathaway</option>
                                    <option value="63e55fc2dd2b2b7e69b5f36e">Ray Rahal</option>
                                    <option value="640b8f9fbf6f0e35eeb4ad91">Sam Fowler</option>
                                    <option value="640676f937a3ba497c4e560a">Sam Simpson</option>

                                </Input>
                            </FormGroup>
                        </div>
            
                        <div className="archk-calendar-footer border-top">
                            <p className="text-sm mb-0 text-white mt-5">
                                <button className='btn btn-success btn-block btn-lg' onClick={runRedirect}>Get Consult!</button>
                            </p>
                        </div>
                
                    </div>
            </div>

       </Container>
    )
}

export default Consult;