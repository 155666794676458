// import Book from '../pages/Book';
import Outcome from '../pages/Outcome';
import Contact from '../pages/Contact';
import Book2 from '../pages/Book2';
import GetLive from '../pages/GetLive';
import Consult from '../pages/Consult';
import Survey from '../pages/Survey';
import Start from '../pages/Start';

const routes = [
    {
        path: "/outcome/:consultation_id",
        component: Outcome,
    },
    {
        path: "/book",
        component: Book2,
    },
    {
        path: "/contact",
        component: Contact,
    },
    {
        path: "/test/live",
        component: Book2,
    },
    {
        path: "/live/:user",
        component: GetLive,
    },
    {
        path: "/consult",
        component: Consult,
    },
    {
        path: "/nps",
        component: Survey,
    },
    {
        path: "/s/:consultation_id",
        component: Start,
    },
    {
        path: "/",
        component: Book2,
    },
  
]

export default routes;