import { useCallback, useState } from 'react';
import { FormGroup, Input, Row, Col, FormFeedback, Progress } from 'reactstrap';
import moment from 'moment-timezone';
import { getTimezoneName } from './timezones'

import _nylas from '_functions/nylas';

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import { getUrlParameter } from 'utils/urls';

// list of lead sources to give live consults to
const liveConsults = [
    // ident
    '6354059352f54ce124ed4e51',
    // jj
    '6354059352f54ce124ed4e35',

    // over deliver and call center ||||||| DO NOT SEND THIS TO IDENT!!!!!
    // '63d45a6b39d1693ffa453969',
    // over deliver and fairmax
    '63d8462e39d1693ffa45627a',
    // over deliver and kevin
    '641492f6e4b5630457e123f1',
    // over deliver media
    '63af0a3eec53e2654dd04e7e',
    // over deliver aged
    '6430533be8f1fb0af1ad7a82',
    // over deliver aged 2
    '646b430cc917817d77a15174',
    
    // in2Sight inbound calls
    '63f6b0932c51b52339c9a116',
    // in2Sight
    '63e4187ddd2b2b7e69b5d418',
    // in2Sight brochure qr code
    '640fcd1d74cf5f2b057f5b95',
    // in2Sight erc calculator
    '641daa51628f597cf7cf0170',
    // in2Sight cb lander
    '63e410dadd2b2b7e69b5d0c5',
    // in2Sight google
    '640b75abbf6f0e35eeb4a720',
    // in2Sight PMAX
    '644837ab25ed55463f9751b9',
    // in2Sight linked in
    '640b75b9bf6f0e35eeb4a721',
    // in2Sight no show reschedule
    '6421a277628f597cf7cf552a',
    
    // test live
    '6436ec2635a858642871d0c4',
    
    // Frascogna
    '64484b3125ed55463f975948',
    
    // DD
    '64484af125ed55463f97593a',
    
    // French Angel Fish
    '6436c61435a858642871c135',
    // Pro v1
    '64679ad29937d75ee5aee7dc',
]

const ArchCalendarBookingStartLive = ({
    setView,
    
    errs,
    givenName,
    familyName,
    email,
    phone,
    companySize,
    companyName,
    consultationId,

    setGivenName,
    setFamilyName,
    setPhone,
    setEmail,
    setCompanyName,
    setErrs,
    setContact,

    mainColor
}) => {

    const [clicks, setClicks] = useState(0)
    const [isLive, setIsLive] = useState(false)

    const incrementClicks = useCallback(() => {
        let _clicks = clicks + 1;
        if(_clicks >= 2) {
            setIsLive(true)
            document.body.style.background = 'black'

            // Create a new, plain <span> element
            let sp1 = document.createElement("div");
            sp1.setAttribute('id', 'live-consults');
            sp1.innerHTML = '<div class="text-center" style="color: #11cdef; text-uppercase; margin-bottom: 10px; max-width: 325px; margin-left: auto; margin-right: auto;">This consult will now be setup for a live transfer to our attorney hotline.</div>'



            // Get the reference element
            let sp2 = document.querySelector('.z-depth-5')
            // Get the parent element
            let parentDiv = sp2.parentNode;

            // Insert the new element into before sp2
            parentDiv.insertBefore(sp1, sp2);

            document.getElementById('name-input').focus()

        } else {
            setClicks(_clicks);
    
        }
    }, [clicks])

    const onSubmit = useCallback(async () => {

        let _errs = [];

        if(!givenName) _errs.push('givenName');
        if(!familyName) _errs.push('familyName');
        if(!companyName) _errs.push('companyName');
        if(!email) _errs.push('email');
        if(!phone) _errs.push('phone');

        if(_errs.length) return setErrs(_errs);

        const lead_source = getUrlParameter('l')



        if(isLive) {
            toggleStandardLoader(true)
            const created = await _nylas.live.createPhillipsLead({
                givenName,
                familyName,
                companyName,
                companySize,
                email,
                phone,
                lead_source
            })
            toggleStandardLoader(false)


            if(created.success) {
                setView('live consult confirmed') 
            } else {
                if(created.errs) {
                    setErrs(created.errs)
                } else {
                    if(created.message === 'This time is no longer available, please choose another time slot.') {
                        setView('select time')
                        toggleAlertBS('info', created.message)
                    } else if(created.message === 'It looks like you already have a case open with Biz Head Law. Please reach out to your contact directly or email operations@bizheadlaw.com should you have any questions.') {
                        toggleAlertBS('info', created.message)
                    } else {
                        toggleAlertBS(true, `It looks like we're experiencing some technical difficulties, please try again.`)
                    }
                }
            }
      

        } else {
            toggleStandardLoader(true)
            const created = await _nylas.live.createLead({
                givenName,
                familyName,
                companyName,
                companySize,
                email,
                phone,
                lead_source,
                url: window.location.href
            })
            toggleStandardLoader(false)


            if(created.success) {
                let link = 'select day'
                if(liveConsults.includes(lead_source)) {
                    
                    const hours = parseInt(moment().tz('America/New_York').format('HH'))

                    if(hours >= 10 && hours <= 18) {
                        if(
                            companySize === '1 - 10' ||
                            companySize === '11 - 20' ||
                            companySize === '21 - 30' ||
                            companySize === '31 - 50'
                        ) {
                            link = 'select type'
                        }
                    }
                }
                setContact(created.data._id)
                setView(link) 
                // setView(liveConsults.includes(lead_source) && companySize !== '100+' ? 'select type' : 'select day') 
            } else {
                if(created.errs) {
                    setErrs(created.errs)
                } else {
                    if(created.message === 'This time is no longer available, please choose another time slot.') {
                        setView('select time')
                        toggleAlertBS('info', created.message)
                    } else if(created.message === 'It looks like you already have a case open with Biz Head Law. Please reach out to your contact directly or email operations@bizheadlaw.com should you have any questions.') {
                        toggleAlertBS('info', created.message)
                    } else {
                        toggleAlertBS(true, `It looks like we're experiencing some technical difficulties, please try again.`)
                    }
                }
            }
      
        }
              

    }, [givenName, familyName, email, phone, setErrs, setView, companySize, companyName, setContact, isLive])

    return (
        <div className="archk-calendar-wrapper">
            
            <div className="archk-calendar-header pb-0">
                <h2   style={{color: mainColor ? mainColor : '#FF8D00'}} className=" mb-0 display-3"><i onClick={incrementClicks} className="fas fa-info-circle text-white mr-2 " /> Get Connected Immediately</h2>
            </div>

            <div className="archk-calendar-header text-cener position-relative pt-0">

                {/* <button className="btn btn-secondary my-4" onClick={() => setView('select type')} >
                    <i className="fas fa-arrow-left mr-3 cursor-pointer" />
                    Go Back
                </button> */}

                <h3  style={{color: mainColor ? mainColor : '#fff'}} className="mb-0 mt-4">Speak With A Live ERC Tax Attorney Now</h3>

                <div className="mt-3">
                    <h3 className="text-cente mb-0">Contact Info</h3>
                    <p className="text-sm "><small>For our attorneys to get in touch with you please fill out the information below. <b className="text-underline">We will then either call you shortly after submission <b className='text-white'>OR</b> if you prefer you can schedule a time to talk at a later date.</b></small></p>
                </div>
           
            </div>
    
            <div className="archk-calendar-body">
                <Row>
                    <Col xs={6}>
                        <FormGroup>
                            <label className="form-control-label">First Name*</label>
                            <Input 
                                id="name-input"
                                type="text"
                                value={givenName}
                                onChange={(e) => setGivenName(e.target.value)}
                                invalid={errs.includes('givenName')}
                                valid={!errs.includes('givenName') && givenName}
                            />
                            <FormFeedback><span style={{color: mainColor ? mainColor : '#FF8D00'}}>Please Enter Your First Name.</span></FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col xs={6}>
                        <FormGroup>
                            <label className="form-control-label">Last Name*</label>
                            <Input 
                                type="text"
                                value={familyName}
                                onChange={(e) => setFamilyName(e.target.value)}
                                invalid={errs.includes('familyName')}
                                valid={!errs.includes('familyName') && familyName}
                            />
                            <FormFeedback><span style={{color: mainColor ? mainColor : '#FF8D00'}}>Please Enter Your Last Name.</span></FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup>
                    <label className="form-control-label">Company Name*</label>
                    <Input 
                        type="text"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        invalid={errs.includes('companyName')}
                        valid={!errs.includes('companyName') && companyName}
                    />
                    <FormFeedback><span style={{color: mainColor ? mainColor : '#FF8D00'}}>Please Enter A Valid Email Address.</span></FormFeedback>
                </FormGroup>
                
                <FormGroup>
                    <label className="form-control-label">Email*</label>
                    <Input 
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        invalid={errs.includes('email')}
                        valid={!errs.includes('email') && email}
                    />
                    <FormFeedback><span style={{color: mainColor ? mainColor : '#FF8D00'}}>Please Enter A Valid Email Address.</span></FormFeedback>
                </FormGroup>

                <FormGroup>
                    <label className="form-control-label">Phone* (We will call you at this number)</label>
                    <Input 
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        invalid={errs.includes('phone')}
                        valid={!errs.includes('phone') && phone}
                    />
                    <FormFeedback><span style={{color: mainColor ? mainColor : '#FF8D00'}}>Please Enter A Valid Phone Number.</span></FormFeedback>
                </FormGroup>

            </div>

            <div className="archk-calendar-body">
                <button 
                    onClick={onSubmit} 
                    className="btn btn-primary btn-block btn-lg"
                    style={{background: mainColor ? mainColor : '#FF8D00'}}
                >
                    <i className="fas fa-check-double mr-2 " /> Get My Consultation
                </button>

                <p className="text-sm mb-0 mt-4"><small>By submitting, you agree to the <a href="https://www.bizheadlaw.com/terms-and-confitions" target="_blank" rel="noopener noreferrer">TOS</a> and  <a href="https://bizheadlaw.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>, and you agree the phone number you provided may be used to be contacted by us (including autodialed, pre-recorded calls or text messages). If you want us to contact you without consenting to these methods of communication, call or email us.</small></p>
            </div>
        </div>
    )

}

export default ArchCalendarBookingStartLive;