import _nylas from '_functions/nylas';
import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';
import { getUrlParameter } from 'utils/urls';

const ArchCalendarBookingSelectType = ({mainColor, setView, companySize, companyName, contact, setQueue}) => {

    const onLiveConsultation = async () => {
 
        const lead_source = getUrlParameter('l')

        toggleStandardLoader(true)
        const created = await _nylas.live.createConsultation({
            companyName,
            companySize,
            contact,
            lead_source,
            booking_link: window.location.href
        })

        toggleStandardLoader(false)

        // contact       : { type: 'object_id', optional: true, existsIn: { collection: 'contacts', returnDoc: true } },
        // lead_source   : { type: 'object_id', optional: true, existsIn: { collection: 'lead_sources', returnDoc: true } },
        // companySize   : { type: 'string' },
        // companyName   : { type: 'string' },
        if(created.success) {
            setView('connect live')
            setQueue(created.queue)
        } else {
            console.log(created)
            toggleAlertBS(true, `It looks like we're experiencing some technical difficulties, please try again.`)
        }
    }

    return (
        <div className="archk-calendar-wrapper">
                        
            <div className="archk-calendar-header">
                <h2 style={{color: mainColor ? mainColor : '#FF8D00'}} className="mb-4 display-3"> Are You Available Now<i className="fas fa-question text-white ml-2 " /></h2>
                {/* <h3 className="mb-0"> Selected Time:</h3>
                <p className="mb-0 text-sm"> </p> */}
            </div>
    
            <div className="archk-calendar-body">
                <p className="text-white font-weght-bold">If you'd like to speak to immediately for a live consultation click the button below.</p>
                <button onClick={onLiveConsultation} style={{background: mainColor ? mainColor : '#FF8D00'}} className='btn btn-block btn-lg btn-warning text-uppercase'>Talk Now</button>

                <p className="text-white text-center font-weight-bold mt-4">OR</p>
                
                <p className="text-white font-weght-bold mt-4">If you'd like to schedule a consultation for a later date click the blue button here.</p>

                <button onClick={() => setView('select day')} className='btn btn-block btn-lg btn-info text-uppercase'>Schedule Now</button>


            </div>

        </div>
    )
}

// talk now or schedule for later
// Interesting things under the loader

// The employee retention credit program is a stimlus program, the intention of which is to circulate money into the economy and created an insencitve to pay employees during the pandemic

export default ArchCalendarBookingSelectType;