import moment from 'moment-timezone';
import { getTimezoneName } from './timezones'

import { Row, Col } from 'reactstrap'
import { useState, useEffect } from 'react'

import Attorneys from '../../assets/img/live/attorneys.svg'
import Calendar from '../../assets/img/live/calendar.svg'
import Timeline from '../../assets/img/live/timeline.svg'
import Attorney from '../../assets/img/live/attorney.svg'
import Info from '../../assets/img/live/info.svg'
import People from '../../assets/img/live/people.svg'
import Call from '../../assets/img/live/call.svg'
import Confirmation from '../../assets/img/live/confirmation.svg'
import Found from '../../assets/img/live/found.svg'
import Busy from '../../assets/img/live/busy.svg'

import Circle from 'components/markup/loading/Circle';
import Dots from 'components/markup/loading/Dots';

// const interval = 3000

import nylas from '_functions/nylas'
import { getUrlParameter } from 'utils/urls';


const Progress = ({interval, type}) => {

    const [percent, setPercent] = useState(0)

    let bg = 'bg-success' 

    if(type === 'calendar' || type === 'attorney' || type === 'call') bg = 'bg-info'



    useEffect(() => {
        setPercent(1)

        function randomIntFromInterval(min, max) { // min and max included 
            return Math.floor(Math.random() * (max - min + 1) + min)
        }
    
        const rndInt = randomIntFromInterval(3, 9) * .1

        let _percent = 0

        let _interval = setInterval(() => {
            _percent += 100 / 10 / rndInt
            setPercent(_percent)

        }, interval / 10 / rndInt)
        
      

        return () => {
            clearInterval(_interval)
        }
    }, [type, interval])

    return (
        <div className="progress-wrapper mt--5">
            <div className="progress-info">
                <div className="progress-percentage">
                {/* <span className="text-sm font-weight-bold">{percent}%</span> */}
                </div>
            </div>
            <div className="progress">
                <div 
                    className={`progress-bar ${bg}`} 
                    role="progressbar" 
                    aria-valuenow={percent} 
                    aria-valuemin="0" 
                    aria-valuemax="100" 
                    style={{width: percent + '%'}}
                ></div>
            </div>
        </div>
    )
}

const ArchCalendarBookingConnectLive = ({mainColor, setView, queue}) => {

    const [type, setType] = useState('schedule')
    const [loop, setLoop] = useState(0)
    const [name, setName] = useState('')

    useEffect(() => {
        let interval = loop === 0 ? 10000 : 15000
        let timeout;

        if(type === 'schedule') {
            timeout = setTimeout(() => {
                setType('calendar')
            }, interval)
        } else if(type === 'calendar') {
            timeout = setTimeout(() => {
                setType('timezone')
            }, interval)
        } else if(type === 'timezone') {
            timeout = setTimeout(() => {
                setType('attorney')
            }, interval)
        } else if(type === 'attorney') {
            timeout = setTimeout(() => {
                setType('info')
            }, interval)
        } else if(type === 'info') {
            timeout = setTimeout(() => {
                setType('call')
            }, interval)
        } else if(type === 'call') {
            timeout = setTimeout(() => {
                setType('confirmation')
            }, interval)
        } else if(type === 'confirmation' && loop === 0) {
            timeout = setTimeout(() => {
                setLoop(1)
                setType('schedule2')
            }, interval)
        } else if(type === 'confirmation' && loop === 1) {
            timeout = setTimeout(() => {
                setType('busy')
            }, interval)
        } else if(type === 'schedule2') {
            timeout = setTimeout(() => {
                setType('calendar')
            }, interval)
        }

        let pollInterval = setInterval(async () => {
            const data = await nylas.live.poll(queue)
            console.log(data);
            if(data.data) {
                setType('found')
                setName(data.data.name)
                clearInterval(pollInterval)
                clearTimeout(timeout)
            }
        }, 1000)

        return () => {
            clearInterval(pollInterval)
            clearTimeout(timeout)
        }

    }, [type, loop, queue])

    useEffect(() => {
        const leadSource = getUrlParameter('l')
        if(leadSource === '6436c61435a858642871c135') {
            const script = document.createElement("script");

            script.src = "https://www.googletagmanager.com/gtag/js?id=AW-11147233990";
            script.async = true;
        
            document.body.appendChild(script);

            
            try {
                const script2 = document.createElement("script");

                script2.src = "bat.bing.com/bat.js";
                script2.async = true;
            
                document.body.appendChild(script2);
            } catch(e) {
                console.log(e)
            }

            setTimeout(() => {

                try {
                    new window.UET({
                        ti: "187040264",
                        enableAutoSpaTracking: true
                    })
                } catch(e) {
                    console.log(e)
                }
                
                window.dataLayer = window.dataLayer || [];
                function gtag(){window.dataLayer.push(arguments);}
                gtag('js', new Date());
              
                gtag('config', 'AW-11147233990');
            }, [2000])

        } else if(leadSource === '640b75abbf6f0e35eeb4a720') {
            const script = document.createElement("script");

            script.src = "https://www.googletagmanager.com/gtag/js?id=AW-11114635531";
            script.async = true;
        
            document.body.appendChild(script);

            setTimeout(() => {
                window.dataLayer = window.dataLayer || [];
                function gtag(){window.dataLayer.push(arguments);}
                gtag('js', new Date());
              
                gtag('config', 'AW-11114635531');
                gtag('event', 'conversion', {'send_to': 'AW-11114635531/3yD_CK7LmpEYEIvC77Mp'});
            }, [2000])
        }
    }, [])

    return (
        <div className="archk-calendar-wrapper">
                        
            <div className="archk-calendar-header">
                <h2 style={{color: mainColor ? mainColor : '#FF8D00'}} className="mb--4 display-4">
                    {
                        type === 'schedule' ? 'Finding Attorneys' :
                        type === 'calendar' ? 'Checking Calendars' :
                        type === 'timezone' ? 'Matching Timezones' :
                        type === 'attorney' ? 'Informing Attorney' :
                        type === 'info' ? 'Sending Information' :
                        type === 'call' ? 'Calling Consultant' :
                        type === 'confirmation' ? 'Getting Confirmation' :
                        type === 'schedule2' ? `Let's Try Again` :
                        type === 'found' ? 'Consultant Found!' :
                        type === 'busy' ? <span><i className="fas fa-info-circle mr-2 text-white"></i> Time Conflict</span> :
                        ''
                    }
                    {type !== 'found' && type !== 'busy' ? <Dots /> : ''}
                    </h2>
                    <p className="text-white mt-3 font-weight-bold">
                        {type === 'busy' ? 'We apologize and will call you shortly.' : 'Expect a call from (866) 699-2491'}
                    </p>
                    {type !== 'found' && type !== 'busy' ? (
                        <Progress 
                            interval={loop === 0 ? 10000 : 15000}
                            type={type}
                        />
                    ) : ''}

                   
            </div>
    
            <div className={`archk-calendar-body ${type !== 'found' && type !== 'busy' ? 'border-bottom' : ''}`}>
                    {/* Checking our database for a list of available attorneys<Dots /> */}
                    {type !== 'found' && type !== 'busy' ? (
                        <p className="text-white">
                            {type !== 'schedule2' ? <b>Did You Know:{' '}</b>  : ''}
                            {
                                type === 'schedule' ? 'Your business could receive up to $26,000 per employee under the Employee Retention Tax Credit Act.' :
                                type === 'calendar' ? 'The employee retention credit program is a stimulus program, the intention of which is to circulate money into the economy and which created an incentive to pay employees during the pandemic.' :
                                type === 'timezone' ? `No matter the industry or business you are in, you could be entitled to receive money from this Federal Relief Program created under the CARES Act.` :
                                type === 'attorney' ? `The IRS expects 80% of small businesses to claim this powerful incentive.` :
                                type === 'info' ? `We've found over $500 million in government incentives for businesses just like yours. ` :
                                type === 'call' ? `Biz Head is quality controlled by Tax Attorneys with years of experience to keep you safe and reduce the risk of an audit.` :
                                type === 'confirmation' ? `Biz Head is quality controlled by Tax Attorneys with years of experience to keep you safe and reduce the risk of an audit.` :
                                type === 'schedule2' ? <span><i className="fas fa-info-circle text-info"></i> It looks like the attorney we found is no longer available, give us one more minute while we find an open consultant.</span> :
                                ''
                            }
                        </p>
                    ) : (
                        <div>
                            <p className="text-white">
                                {type === 'found' ? (
                                    <span>We've found your consultant, you should receive a call from <b className='text-underline text-capitalize text-white font-weight-bold'>{name}</b> momentarily from the phone number (866) 699-2491.</span>
                                ) : (
                                    <span>It looks like we ran into a booking conflict. <b className='text-underline text-white'>We know your time is valuable</b> so don't worry we have your information saved and will give you a call as soon as we can. In the meantime if you'd like to book a scheduled consultation please click the button below <br/><br />Make sure to store the following number in your phone so you know it's us when we call you: <b className='text-underline text-white font-weight-bold'>(866) 699-2491</b><br /> </span>
                                )}
                            </p>

                            {type !== 'found' ? (
                                <button onClick={() => setView('select day')} className='btn btn-warning btn-block btn-lg my-4'>Schedule A Time</button>
                            ) : ''}
                        
                            <div className='text-center'>
                                <img 
                                    src={type === 'found' ? Found : Busy}  
                                    className="mb-0 border bg-secondary p-3 mt-5 mb-4" 
                                    style={{ borderRadius: '10%', width: '60%', background: ''}} 
                                    alt="..." 
                                />
                            </div>
                        </div>
                    )}

            </div>
            
            {type !== 'found' && type !== 'busy' ? (
                <div>

                    <div className="archk-calendar-body text-center pt-4" style={{background: '#fff'}}>
                        <Row>
                            <Col xs={12} className="align-self-center">
                            <img 
                                src={
                                    type === 'schedule' ? People : 
                                    type === 'calendar' ? Calendar : 
                                    type === 'timezone' ? Timeline : 
                                    type === 'attorney' ? Attorney : 
                                    type === 'info' ? Info : 
                                    type === 'call' ? Call : 
                                    type === 'schedule2' ? People : 
                                    type === 'confirmation' ? Confirmation : 
                                    ''
                                } 
                                className="mb-0 border bg-secondary p-3 mb-4" 
                                style={{ borderRadius: '10%', width: '60%', background: ''}} 
                                alt="..." 
                            />
                            <Circle />

                            </Col>
                            {/* <Col xs={4} className="align-self-center">
                            <Circle />

                            </Col> */}
                        </Row>
                    </div>

                    <div className="archk-calendar-body bg-white text-center pt-4 border-top">
                        <p className='text-sm mb-0 mt-0 text-dark font-weight-bold'>Estimated Time Till Consult: 1 Minute</p>
                    </div>
                    <div className="archk-calendar-footer border-top">
                        <p className="text-white mb-0">
                            {
                                type === 'info' ?  `Sending your information to our attorney.` : 
                                type === 'call' ?  `Trying to get your attorney on the phone.` : 
                                type === 'confirmation' ?  `Awaiting final consultant confirmation.` : 
                                `Sit tight, we're currently searching for your attorney.`
                            }
                        </p>
                    </div>

                </div>
            ) : ''}
           

        </div>
    )
}

export default ArchCalendarBookingConnectLive;