import { useCallback, useState } from 'react';
import { FormGroup, Input, FormFeedback } from 'reactstrap';

const ArchCalendarBookingEnterQualifications = ({
    titleEnterQualifications,    
    companySize,
    setCompanySize,
    setView,
    mainColor
}) => {

    const [errs, setErrs] = useState([])

    const onSubmit = useCallback(async () => {

        let _errs = [];

        if(!companySize || companySize === 'false') _errs.push('companySize');
        if(_errs.length) return setErrs(_errs);

        setView('start live')

    }, [companySize, setView])

    return (
        <div className="archk-calendar-wrapper">
            
            <div className="archk-calendar-header pb-0">
                <h2 style={{color: mainColor ? mainColor : '#FF8D00'}} className="mb-0 display-3"><i className="fas fa-info-circle text-white mr-2 " /> {titleEnterQualifications}</h2>
            </div>

            <div className="archk-calendar-header text-cener position-relative pt-0">

                <p className="text-sm"><span>Get a Free consultation in under 60 seconds!</span></p>


                <div className="mt-3">
                    <h3 className="text-cente ">Your Employee Retention Tax Credit Is Right Around The Corner</h3>
                    <p className="text-sm ">We've brought millions of dollars in IRS tax credits into businesses just like yours. Book a consultation with us to see just how much we can bring to you.</p>
                </div>
           
            </div>
    
            <div className="archk-calendar-body mb-4">
                <FormGroup>
                    <label className="form-control-label">How Many People Are In Your Company?*</label>
                    <Input 
                        type="select"
                        value={companySize}
                        onChange={(e) => setCompanySize(e.target.value)}
                        invalid={errs.includes('companySize')}
                        valid={!errs.includes('companySize') && companySize}
                    >
                        <option value="false">Click To Select</option>
                        <option value="1 - 10">1 - 10</option>
                        <option value="11 - 20">11 - 20</option>
                        <option value="21 - 30">21 - 30</option>
                        <option value="31 - 50">31 - 50</option>
                        <option value="51 - 100">51 - 100</option>
                        <option value="100+">100+</option>
                    </Input>
                    <FormFeedback><span style={{color: mainColor ? mainColor : '#FF8D00'}}>Please Select How Many People Are In Your Company.</span></FormFeedback>
                </FormGroup>
            </div>

            <div className="archk-calendar-body">
                <button 
                    onClick={onSubmit} 
                    className="btn btn-primary btn-block btn-lg"
                    style={{background: mainColor ? mainColor : ''}}
                >
                    Select A Time <i className="fas fa-arrow-right ml-2 " />
                </button>
            </div>
        </div>
    )

}

export default ArchCalendarBookingEnterQualifications;