import { useEffect, useCallback, useState } from 'react';
import { Container, FormGroup, Input, FormFeedback } from 'reactstrap';
import Logo from 'assets/img/brand/BizHeadLogoCut.png'
import _nylas from '_functions/nylas'

import Circle from 'components/markup/loading/Circle'
import Dots from 'components/markup/loading/Dots'

import { getUrlParameter } from 'utils/urls';

const Book = ({match}) => {

    const [loading, setLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);
    // const [validationErr, setValidationErr] = useState(false);
    // const [note, setNote] = useState('');
    // const [outcome, setOutcome] = useState('');
    const [state, setState] = useState({});
    const [consultId, setConsultId] = useState('');
    // const [success, setSuccess] = useState(false);
    // const [disabled, setDisabled] = useState(false);

    // const { start, givenName, familyName, companySize, company_name, email, phone } = state;

    // const onSubmit = useCallback(async () => {
    //     if(!outcome || outcome === false) setValidationErr(true)

    //     setDisabled(true)
    //     const data = await _nylas.finishConsultation(match.params.consultation_id, { outcome, note })
    //     setDisabled(false)

    //     if(data.data) {
    //         setSuccess(true);
    //     } else {
    //         toggleAlertBS(true, `Something's not right, please try again.`)
    //     }

    // }, [match.params.consultation_id, outcome, note])

    const fetchData = useCallback(async () => {
        setLoading(true)
        const data = await _nylas.live.findNext(match.params.user, getUrlParameter('_id'));
        setLoading(false)
        
        if(data.data) {
            setState(data.data)

            setTimeout(async () => {
                const consult = await _nylas.live.getLiveConsultation(data.data.contact_id);
                if(consult) setConsultId(consult)

            }, 10000)
        } else {
            setNotFound(true)
        }
    }, [match.params.user])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
       <Container className="pt-5">
        

            <div style={{maxWidth: 360, margin: 'auto', }} className="z-depth-5 border rounded pb-3">
                <div className="text-centr pl-3 pt-3 ml-1">
                    <img src={Logo} style={{width: 225}} alt="Biz Head Law" />
                </div>

                <div className='p-5'>
                {loading ? (
                    <div>
                        <p className='text-sm text-center font-weight-bold my-5 text-white'>Searching Consultations</p>
                        <Circle />
                    </div>
                ) : notFound ? (
                    <div>
                        <p className='text-sm text-center font-weight-bold my-5 text-warning'>
                            {getUrlParameter('_id') ? 'This consultation has been taken.' : 'No consultations found, refresh the page to check again.'}
                            
                        </p>
                    </div>
                ) : (
                    <div>
                        <p className='text-sm font-weight-bold -5 text-success text-capitalize'>Consultation Found</p>
                        <p className='text-sm text-white mb-0 text-capitalize'><span style={{display: 'inline-block', width: 100}}>Name:</span>{state.name}</p>
                        <p className='text-sm text-white mb-0 text-capitalize'><span style={{display: 'inline-block', width: 100}}>Company: </span>{state.case}</p>
                        <p className='text-sm text-white mb-0'><span style={{display: 'inline-block', width: 100}}>Size: </span>{state.company_size}</p>
                        <p className='text-sm text-white mb-0'><span style={{display: 'inline-block', width: 100}}>Email: </span>{state.email}</p>
                        <p className='text-sm text-white mb-0'><span style={{display: 'inline-block', width: 100}}>Phone: </span>{state.phone}</p>

                        <p className='mt-3 text-white'>Calling You Now From (866) 699-2491<Dots /></p>

                        {consultId ? (
                            <a className="btn btn-warning btn-block my-4" href={`/outcome/${consultId}`} target="_blank" rel="noopener noreferrer">Click here to disposition the consult</a>
                        ) : ''}
                    </div>
                )}
                </div>

            </div>

       </Container>
    )
}

export default Book;