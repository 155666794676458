/*

https://services.leadconnectorhq.com/hooks/JvhUGjEkhrwRhn73VrTJ/webhook-trigger/aede76f7-b3da-450e-b8cf-b9745e64049f

{
   "NPS Score": "8", 1 / 10
   "last": "Mento",
   "first": "Mike",
   "business": "In2sight LLC",
   "email": "mike@in2sight.net"
 }

*/

// http://localhost:3014/nps?email=john@architeck.io
import { useEffect, useCallback, useState } from 'react';
import { Container, FormGroup, Input, FormFeedback } from 'reactstrap';
import Logo from 'assets/img/brand/BizHeadLogoCut.png'

import _nylas from '_functions/nylas'

import Circle from 'components/markup/loading/Circle'
import moment from 'moment';
import { toggleAlertBS } from 'store/functions/system/system';
import { getUrlParameter } from 'utils/urls';

const Book = ({match}) => {

    const [score, setScore] = useState('');
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState('');

  
    
    const onSubmit = useCallback(async () => {
        // if(!score) return;


        // setDisabled(true)
        const data = await _nylas.review({
            email,
            score
        })

        setSubmitted(true)

        console.log(data)
  

    }, [email, score])

    useEffect(() => {
        const _email = getUrlParameter('email')
        setEmail(_email)
        window.history.pushState('/nps', '/nps', '/nps');

    }, [])


    return (
       <Container className="pt-5">
        

            <div style={{maxWidth: 360, margin: 'auto', }} className="z-depth-5 border rounded pb-3">
                <div className="text-centr pl-3 pt-3 ml-1">
                    <img src={Logo} style={{width: 225}} alt="Biz Head Law" />
                </div>

                {submitted ? (
                        <div className="px-5 pt-3 pb-4">
                            <h2 className="text-primary display-4">Thank You! </h2>
                            
                            <p>Your feedback is appropriate </p>
                        </div>
                ) : (
                    <div>
                        <div className="px-5 pt-3 pb-4">
                        <h2 className="text-primary display-4">How'd We Do? </h2>
                        
                        <p className='mb-0'>How likely are you to recommend Biz Head Law to a friend or colleague? <br /><br/>1 = not likely at all, 10 = extremely likely </p>
                    </div>

                    <div className="px-5 pt-4 py-5 border-top">
                        <button onClick={() => setScore(1)} style={score === 1 ? btnStyleActive : btnStyle}>1</button>
                        <button onClick={() => setScore(2)} style={score === 2 ? btnStyleActive : btnStyle}>2</button>
                        <button onClick={() => setScore(3)} style={score === 3 ? btnStyleActive : btnStyle}>3</button>
                        <button onClick={() => setScore(4)} style={score === 4 ? btnStyleActive : btnStyle}>4</button>
                        <button onClick={() => setScore(5)} style={score === 5 ? btnStyleActive : btnStyle}>5</button>
                        <button onClick={() => setScore(6)} style={score === 6 ? btnStyleActive : btnStyle}>6</button>
                        <button onClick={() => setScore(7)} style={score === 7 ? btnStyleActive : btnStyle}>7</button>
                        <button onClick={() => setScore(8)} style={score === 8 ? btnStyleActive : btnStyle}>8</button>
                        <button onClick={() => setScore(9)} style={score === 9 ? btnStyleActive : btnStyle}>9</button>
                        <button onClick={() => setScore(10)} style={score === 10 ? btnStyleActive : btnStyle}>10</button>
                    </div>

                    <div className="px-5 pt-4 mb-1 pb-2 border-top">
                        <button disabled={!score} onClick={onSubmit} className="btn btn-primary btn-block btn-lg mb-0">
                            <i className="fas fa-paper-plane mr-2 " /> Submit
                        </button>
                    </div>
                </div>
                )}

            </div>

       </Container>
    )
}

const btnStyle = {
    
        width: '9%',
        border: 'solid 1px gray',
        borderRadius: 3,
        marginRight: '1%',
        padding: 0,
        textAlign: 'center',
        cursor: 'pointer'

}
const btnStyleActive = {
    
        width: '9%',
        border: 'solid 1px #2dce89',
        borderRadius: 3,
        marginRight: '1%',
        padding: 0,
        textAlign: 'center',
        cursor: 'pointer',
        background: '#2dce89'

}

export default Book;