import Logo from 'assets/img/brand/BizHeadLogoCut.png';
import { useCallback, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import _nylas from '_functions/nylas';

import Circle from 'components/markup/loading/Circle';

const Book = ({match}) => {

    const [err, setErr] = useState('');
    const [loading, setLoading] = useState(true);
    const [link, setLink] = useState('');

  
    
    const fetchEvent = useCallback(async () => {
        const data = await _nylas.startFromLink(match.params.consultation_id)
        if(data.data) return setTimeout(() => {
            setLink(data.data)
            window.location.href = data.data
        }, 1500)

        return setErr(true)
    }, [match.params.consultation_id])

    useEffect(() => {
        fetchEvent()
    }, [fetchEvent])


    return (
       <Container className="pt-5">
        

            <div style={{maxWidth: 360, margin: 'auto', }} className="z-depth-5 border rounded pb-3">
                <div className="text-centr pl-3 pt-3 ml-1">
                    <img src={Logo} style={{width: 225}} alt="Biz Head Law" />
                </div>

                {err ? (
                    <div className="px-5 pt-3 pb-4">
                        <h2 className="text-primary display-4">Something's Not Right </h2>
                        <p>It looks like we can't find your consultation, please try refreshing the page </p>
                    </div>
                ) : loading && !link ? (
                    <div className="px-5 pt-3 pb-4">
                    <h2 className="text-primary display-4">Getting Consultation Details </h2>
                    <p>You should be automatically redirected to a google meet link shortly </p>
                    <Circle />
                </div>
                ) : (
                    <div className="px-5 pt-3 pb-4">
                    <h2 className="text-primary display-4">All Set! </h2>
                    <p>If your page does not automatically get redirected to your meeting please click the Google Meet link below: </p>
                    <p><a href={link}>{link}</a></p>
                </div>
                )}
            </div>

       </Container>
    )
}

const btnStyle = {
    
        width: '9%',
        border: 'solid 1px gray',
        borderRadius: 3,
        marginRight: '1%',
        padding: 0,
        textAlign: 'center',
        cursor: 'pointer'

}
const btnStyleActive = {
    
        width: '9%',
        border: 'solid 1px #2dce89',
        borderRadius: 3,
        marginRight: '1%',
        padding: 0,
        textAlign: 'center',
        cursor: 'pointer',
        background: '#2dce89'

}

export default Book;