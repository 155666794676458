import { useEffect, useCallback, useState } from 'react';
import { Container, FormGroup, Input, FormFeedback } from 'reactstrap';
import Logo from 'assets/img/brand/BizHeadLogoCut.png'

import _nylas from '_functions/nylas'

import Circle from 'components/markup/loading/Circle'
import moment from 'moment';
import { toggleAlertBS } from 'store/functions/system/system';
import { getUrlParameter } from 'utils/urls';

const Book = ({match}) => {

    const [outcome, setOutcome] = useState('');
    const minutes = parseInt(moment().format('mm'))

    let showButton = false;
    if(outcome && outcome.includes('No Show')) {
        if(minutes >= 0 && minutes < 6) {
            showButton = true 
        } else if(minutes >= 30 && minutes < 36) {
            showButton = true
        }
    }

    const [err, setErr] = useState(false);
    const [findConsult, setFindConsult] = useState(showButton ? true : false);
    const [validationErr, setValidationErr] = useState(false);
    const [note, setNote] = useState('');
    const [state, setState] = useState({});
    const [success, setSuccess] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const { start, givenName, familyName, companySize, company_name, email, phone } = state;

  
    
    const onSubmit = useCallback(async () => {
        if(!outcome || outcome === false) setValidationErr(true)

        let live = getUrlParameter('live');
        live = live === 'true' ? true : false 

        setDisabled(true)
        const data = await _nylas.finishConsultation(match.params.consultation_id, { outcome, note, live})
        setDisabled(false)

        if(data.data) {
            setSuccess(true);
            if(findConsult) window.location.href = `/live/${state.user}`
        } else {
            toggleAlertBS(true, `Something's not right, please try again.`)
        }

    }, [match.params.consultation_id, outcome, note, state.user, findConsult])

    useEffect(() => {
        const runQuery = async () => {
            const data = await _nylas.getConsultation(match.params.consultation_id);
            if(data.data) {
                setState(data.data)
            } else {
                setErr(true)
            }
        }

        runQuery();
    }, [match.params.consultation_id])

    return (
       <Container className="pt-5">
        

            <div style={{maxWidth: 360, margin: 'auto', }} className="z-depth-5 border rounded pb-3">
                <div className="text-centr pl-3 pt-3 ml-1">
                    <img src={Logo} style={{width: 225}} alt="Biz Head Law" />
                </div>

                {success ? (
                    <div className="px-5 pt-3 mb-1">
                        <h2 >
                            <i className="fas fa-check text-primary mr-2" /> Consultation Outcome Recorded, You May Now Close This Page.
                        </h2>
                    </div>
                ) : err ? (
                    <div className="px-5 pt-3 mb-1">
                        <h2 >
                            <i className="fas fa-exclamation-triangle text-primary mr-2" /> This page has expired.
                        </h2>
                    </div>
                ) : state._id ? (
                    <div>
                         <div className="px-5 pt-3 pb-4">
                            <h2 className="text-primary display-4">Attorney Consultation </h2>
                            {state.lead_source === '64207a40628f597cf7cf4710' ? (
                                <div className="alert alert-danger">This is A KR lead.</div>
                            ) : ''}

                            <p className="text-white mb-0">{moment.unix(start).format('dddd MMM Do, YYYY - h:mm A')}</p>
                            <p className=" mt-0">Size {companySize} Employees</p>

                            <p>Enter the outcome of the call with: </p>

                            <div className="rounded  border-left px-3 pb-3 pt-1 mb-4">
                                <p className="text-sm mb-0">
                                    <span className="d-block text-white font-weight-bold text-capitalize text-underline">{givenName} {familyName}</span>
                                </p>
                                <p className="text-sm mb--2 mt-3">{company_name}</p>
                                <p className="text-sm mb-3">{email}</p>
                                {phone ?  <a className="btn btn-info" href={`tel:${phone}`}><i className="fas fa-phone mr-2 " /> {phone}</a> : ''}
                                {/* <p className="text-sm mb-0">{phone}</p> */}
                            </div>

                            <FormGroup>
                                <label className="form-control-label">Consultation Outcome*</label>
                                <Input 
                                    onChange={(e) => setOutcome(e.target.value)}
                                    value={outcome}
                                    invalid={validationErr}
                                    type="select"
                                >
                                    <option value="false">select outcome</option>
                                  
                                    <optgroup label="Did Not Show">
                                        <option value="No Show - follow up needed">No Show - follow up needed</option>
                                        <option value="No Show - cancelled - no follow up needed">No Show - cancelled - no follow up needed</option>
                                    </optgroup>

                                    <optgroup label="Does Not Qualify">
                                        <option value="Showed - DNQ (not impacted)">Showed - DNQ (not impacted)</option>
                                        <option value="Showed - DNQ (only W2 is owner/family member)">Showed - DNQ (only W2 is owner/family member)</option>
                                        <option value="Showed - DNQ (not interested)">Showed - DNQ (not interested)</option>
                                        <option value="Showed - DNQ (already applied)">Showed - DNQ (already applied)</option>
                                    </optgroup>

                                    <optgroup label="Additional Action Needed">
                                        <option value="Showed - DNH - needs for information">Showed - DNH - needs for information</option>
                                        <option value="Showed - needs follow up call with senior lawyer">Showed - needs follow up call with senior lawyer</option>
                                        {state.hasNewRetainers ? (
                                            <>
                                            <option value="Showed - needs agreement 30%">Showed - needs agreement 30%</option>
                                            <option value="Showed - needs agreement 27.5%">Showed - needs agreement 27.5%</option>
                                            <option value="Showed - needs agreement 25%">Showed - needs agreement 25%</option>
                                            <option value="Showed - needs agreement 22.5%">Showed - needs agreement 22.5%</option>
                                            </>
                                        ) : ''}
                                        <option value="Showed - needs agreement 20%">Showed - needs agreement 20%</option>
                                        <option value="Showed - needs agreement 18%">Showed - needs agreement 18%</option>
                                        <option value="Showed - needs agreement 17.5%">Showed - needs agreement 17.5%</option>
                                        <option value="Showed - needs agreement 15%">Showed - needs agreement 15%</option>
                                    </optgroup>

                                    <optgroup label="No Action Needed">
                                        <option value="Just A Check In Call">Just A Check In Call</option>
                                    </optgroup>

                                </Input>
                                <FormFeedback>Please Enter The Outcome Of The Consultation.</FormFeedback>
                            </FormGroup>

                           

                            <FormGroup>
                                <label className="form-control-label">Note (Optional)</label>
                                <Input 
                                    type="textarea"
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </FormGroup>

                            {outcome && outcome !== false ? (
                                <p className="text-sm">
                                    <small>
                                        Selected Outcome:{' '}
                                        <span className="d-block text-white">
                                            {outcome === 'Just A Check In Call' ? null :
                                                outcome === 'No Show - follow up needed' || outcome === 'No Show - cancelled - no follow up needed' ? (
                                                <i className="fas fa-times mr-2 text-danger " /> 
                                            ) : outcome === 'Showed - DNH - needs for information' || outcome.includes('Showed - needs agreement') || outcome === 'Showed - needs follow up call with senior lawyer' ? (
                                                <i className="fas fa-check mr-2 text-success " /> 
                                            ) : (
                                                <i className="fas fa-thumbs-down down mr-2 text-warning " /> 
                                            )}
                                            {' '}
                                            {outcome}
                                        </span>
                                    </small>
                                </p>
                            ) : null}

                        </div>



                        <div className="px-5 pt-4 mb-1 pb-2 border-top">
                            <button disabled={disabled} onClick={onSubmit} className="btn btn-primary btn-block btn-lg mb-0">
                                <i className="fas fa-paper-plane mr-2 " /> Submit
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="py-6">
                        <Circle />
                    </div>
                )}

            </div>

       </Container>
    )
}

export default Book;