import { useCallback, useState, useEffect } from 'react';

import moment from 'moment-timezone';

import Circle from 'components/markup/loading/Circle'

import SelectDay from './SelectDay'
import SelectTime from './SelectTime'
import EnterInfo from './EnterInfo'
import Confirmation from './Confirmation'
import EnterQualifications from './EnterQualifications'
import SelectType from './SelectType'
import StartLive from './StartLive'
import ConnectLive from './ConnectLive'
import LiveConsultConfirmed from './LiveConsultConfirmed'

import { getUrlParameter } from 'utils/urls';

import _nylas from '_functions/nylas'

const ArchCalendarBooking = ({
    disabledDays, 
    calendarTimezone, 
    bookingInterval, 
    calendarStartTime, 
    calendarEndTime, 
    titleSelectDay, 
    titleSelectTime, 
    titleEnterInfo,
    titleConfirmation,
    titleEnterQualifications,
    theme,
    mainColor,
}) => {

    // 'select day', 'select time', 'enter info'
    // const [type, setType] = useState('schedule')
    const [queue, setQueue] = useState(null)
    const [type, setType] = useState('schedule')
    const [start, setStart] = useState(0)
    const [contact, setContact] = useState(null)
    const [consultationId, setConsultationId] = useState(null)
    const [errNotFound, setErrNotFound] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [view, setView] = useState('enter qualifications')
    const [timezone, setTimezone] = useState(moment.tz.guess())
    const [rows, setRows] = useState(null)
    const [month, setMonth] = useState(moment())
    const [attorney, setAttorney] = useState('')
    const [now] = useState(parseInt(moment().startOf('day').format('X')))

    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)

    const [errs, setErrs] = useState([])

    const [givenName, setGivenName] = useState(window.location.href.includes('localhost') ? 'John' : '');
    const [familyName, setFamilyName] = useState(window.location.href.includes('localhost') ? 'Maher' : '');
    const [email, setEmail] = useState(window.location.href.includes('localhost') ? 'test12345@gmail.com' : '');
    const [phone, setPhone] = useState(window.location.href.includes('localhost') ? '9738975091' : '');
    const [companySize, setCompanySize] = useState(window.location.href.includes('localhost') ? '1 - 10' : '');
    const [companyName, setCompanyName] = useState(window.location.href.includes('localhost') ? 'Architeck' : '');

    const onSelectDate = useCallback((date) => {
        setSelectedDate(date);
        setView('select time')
    }, [])
    
    const onSelectTime = useCallback((date) => {
        setSelectedTime(date)
        setErrs([])
        setView('enter info')
    }, [])

    useEffect(() => {
        const hours = parseInt(moment().tz('America/New_York').format('HH'))
        console.log(hours)

        if(hours >= 10 && hours <= 18) {
            console.log('l time')
        }
    }, [])

    useEffect(() => {

        const m = moment(month).tz(timezone);

        const startOfMonth = m.clone().startOf('month');
        const endOfMonth = m.clone().endOf('month');

        const startOfMonthDay = startOfMonth.isoWeekday();
        const endOfMonthDay = endOfMonth.isoWeekday();
        
        const daysInMonth = startOfMonth.daysInMonth();

        const dates = [];

        if(startOfMonthDay !== 7) {
            for (let i = 0; i < startOfMonthDay; i++) {
                dates.unshift({ value: 0, text: 0 });
            }
        }
        
        for (let i = 0; i < daysInMonth; i++) {
            const day = startOfMonth.clone().add(i, 'day')
            const dayOfWeek = day.isoWeekday();

            let disabled = false;

            if(disabledDays && disabledDays.length) {
                if(disabledDays.includes(dayOfWeek)) disabled = true;
            }

            if(parseInt(day.format('X')) < now) {
                disabled = true;
            }

            dates.push({ 
                value: parseInt(day.format('X')), 
                date: day.toDate(), 
                text: day.format('D'),
                disabled
            });
        }

        for (let i = 6; i > endOfMonthDay; i--) {
            dates.push({ value: 0, text: 0 });
        }

        let _rows = [];
        let counter = 0
        let week = 0;

        for (let i = 0; i < dates.length; i++) {
            const date = dates[i];
            if(!_rows[week]) _rows[week] = { dates: [] };

            _rows[week].dates.push(date);
            counter++;

            if(counter === 7) {
                counter = 0;
                week++;
            }
        }

        setRows(_rows);

    }, [timezone, month, disabledDays])

    useEffect(() => {

        // http://localhost:3014/?l=634edc52441a168466066099&contact_first_name=Testing123&contact_last_name=Testing&intake_email=bpiper90%40gmail.com&contact_phone_number=%28978%29%20888-8888&name=TestingLLC
        const _reschedule = getUrlParameter('reschedule');
        const _lead_id = getUrlParameter('le');

        const _givenName = getUrlParameter('contact_first_name');
        const _familyName = getUrlParameter('contact_last_name');
        const _email = getUrlParameter('intake_email');
        const _phone = getUrlParameter('contact_phone_number');
        const _companyName = getUrlParameter('name');

        if(_givenName) setGivenName(_givenName)
        if(_familyName) setFamilyName(_familyName)
        if(_email) setEmail(_email)
        if(_phone) setPhone(_phone)
        if(_companyName) setCompanyName(_companyName)

        if(!_reschedule && !_lead_id) return setLoaded(true)

        if(_reschedule) {
            const runQuery = async () => {
                const data = await _nylas.getConsultation(_reschedule);
                if(data.data) {
                    setCompanySize(data.data.companySize);
                    setCompanyName(data.data.companyName);
                    setEmail(data.data.email);
                    setPhone(data.data.phone);
                    setGivenName(data.data.givenName);
                    setFamilyName(data.data.familyName);
                    setConsultationId(data.data._id);
                    setStart(data.data.start)
                    setView('select day');
                    setLoaded(true);
                    setType('reschedule')
                } else {
                    setErrNotFound(data.message ? data.message : 'This page has expired.')
                }
            }

            runQuery();
        } else if(_lead_id) {
            const runQuery = async () => {
                const data = await _nylas.getLead(_lead_id);
                if(data.data) {

                    const s = data.data.companySize;
                    const hasSize = s === '1 - 10' || s === '11 - 20' || s === '21 - 30' || s === '31 - 50' || s === '51 - 100' || s === '100+'

                    if(hasSize) setCompanySize(data.data.companySize);
                    if(data.data.companyName) setCompanyName(data.data.companyName);
                    if(data.data.email) setEmail(data.data.email);
                    if(data.data.phone) setPhone(data.data.phone);
                    if(data.data.givenName) setGivenName(data.data.givenName);
                    if(data.data.familyName) setFamilyName(data.data.familyName);
                    if(hasSize) setView('select day');
                    setLoaded(true);
                } else {
                    setErrNotFound(data.message ? data.message : 'This page has expired.')
                }
            }

            runQuery();
        }

    }, [])

    if(errNotFound) return (
        <div className="archk-calendar-booking">
            <div className="archk-calendar-wrapper">
                <div className="archk-calendar-header">
                    <h4><i className="fas fa-exclamation-triangle mr-2 text-primary " /> This Page Has Moved</h4>
                    <p className="text-sm mb-0">{errNotFound}</p>
                </div>
            </div>
        </div>
    )

    if(!rows || !loaded) return (
        <div className="archk-calendar-booking">
            <div className="archk-calendar-wrapper">
                <div className="archk-calendar-header">
                    <Circle />
                </div>
            </div>
        </div>
    )

    return (
        <div className={`archk-calendar-booking ${theme === 'light' ? 'archk-calendar-booking-light' : ''}`}>
            {view === 'enter qualifications' ? (
                <EnterQualifications 
                    titleEnterQualifications={titleEnterQualifications}
                    companySize={companySize}
                    setCompanySize={setCompanySize}
                    setView={setView}
                    mainColor={mainColor}
                />
            ) : view === 'start live' ? (
                <StartLive 
                    setView={setView}

                    errs={errs}
                    givenName={givenName}
                    familyName={familyName}
                    email={email}
                    phone={phone}
                    companySize={companySize}
                    companyName={companyName}

                    setGivenName={setGivenName}
                    setFamilyName={setFamilyName}
                    setPhone={setPhone}
                    setEmail={setEmail}
                    setErrs={setErrs}
                    setCompanyName={setCompanyName}
                    mainColor={mainColor}
                    setContact={setContact}
                />
            ) : view === 'select type' ? (
                <SelectType 
                    titleEnterQualifications={titleEnterQualifications}
                    companySize={companySize}
                    setCompanySize={setCompanySize}
                    setView={setView}
                    mainColor={mainColor}
                    contact={contact}
                    companyName={companyName}
                    setQueue={setQueue}
                />
            ) : view === 'connect live' ? (
                <ConnectLive 
                    titleEnterQualifications={titleEnterQualifications}
                    companySize={companySize}
                    setCompanySize={setCompanySize}
                    setView={setView}
                    mainColor={mainColor}
                    queue={queue}
                />
            )  : view === 'select day' ? (
                <SelectDay 
                    setMonth={setMonth}
                    month={month}
                    rows={rows} 
                    timezone={timezone}
                    setTimezone={setTimezone}
                    onSelectDate={onSelectDate}
                    titleSelectDay={titleSelectDay}
                    type={type}
                    start={start}
                    mainColor={mainColor}
                />
            ) : view === 'select time' ? (
                <SelectTime 
                    timezone={timezone}
                    calendarTimezone={calendarTimezone}
                    bookingInterval={bookingInterval}
                    selectedDate={selectedDate}
                    calendarStartTime={calendarStartTime}
                    calendarEndTime={calendarEndTime}
                    setView={setView}
                    titleSelectTime={titleSelectTime}
                    onSelectTime={onSelectTime}
                    companySize={companySize}
                    mainColor={mainColor}
                />
            ) : view === 'enter info' ? (
                <EnterInfo 
                    consultationId={consultationId}
                    titleEnterInfo={titleEnterInfo}
                    setView={setView}
                    timezone={timezone}
                    selectedTime={selectedTime}

                    selectedDate={selectedDate}
                    calendarTimezone={calendarTimezone}

                    errs={errs}
                    givenName={givenName}
                    familyName={familyName}
                    email={email}
                    phone={phone}
                    companySize={companySize}
                    companyName={companyName}

                    setGivenName={setGivenName}
                    setFamilyName={setFamilyName}
                    setPhone={setPhone}
                    setEmail={setEmail}
                    setErrs={setErrs}
                    setAttorney={setAttorney}
                    setCompanyName={setCompanyName}
                    mainColor={mainColor}
                />
            ) : view === 'confirmation' ? (
                <Confirmation 
                    timezone={timezone}
                    selectedTime={selectedTime}
                    titleConfirmation={titleConfirmation}
                    attorney={attorney}
                    mainColor={mainColor}
                />
            ) : view === 'live consult confirmed' ? (
                <LiveConsultConfirmed   />
            ) : null}
            
        </div>
    )
}

export default ArchCalendarBooking;