import { Modal, Row, Col } from "reactstrap";
import moment from 'moment-timezone';
import timezones from './timezones'

const ArchkCalendarBookingModalSelectTimezone = ({showModal, toggleModal, timezone, setTimezone}) => (
    <Modal
        className="modal-dialog-centered archk-calendar-booking-modal"
        isOpen={showModal}
        toggle={toggleModal}
        size="md"
    >

        <div className="modal-header">
            <h5 className="modal-title">Select Timezone</h5>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
            >
                <span aria-hidden={true}>×</span>
            </button>
        </div>

        <div className="modal-body p-0">
            {timezones.map((t, i) => (
                <div 
                    key={i} 
                    className={`${t.code === timezone ? 'active' : ''} px-4 py-3 archk-calendar-booking-modal-timezone`}
                    onClick={() => {
                        setTimezone(t.code)
                        toggleModal()
                    }}
                >
                    <Row>
                        <Col xs={8}>
                            {t.name}
                        </Col>
                        <Col xs={4} className="text-right">
                            {moment().tz(t.code).format('h:mm A')}
                        </Col>
                    </Row>
                </div>
            ))}
        </div>

        <div className="modal-footer text-right">
            <button className="btn btn-warning" onClick={toggleModal}>
                <i className="fas fa-arrow-left mr-2" />
                Close
            </button>
        </div>

    </Modal>
)

export default ArchkCalendarBookingModalSelectTimezone;
