import { useCallback } from 'react';
import { FormGroup, Input, Row, Col, FormFeedback } from 'reactstrap';
import moment from 'moment-timezone';
import { getTimezoneName } from './timezones'

import _nylas from '_functions/nylas';

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import { getUrlParameter } from 'utils/urls';

const ArchCalendarBookingEnterInfo = ({
    titleEnterInfo,
    setView,
    timezone,
    selectedTime,
    
    givenName,
    familyName,
    email,
    phone,
    companySize,
    companyName,
    consultationId,

    setErrs,

    setAttorney,

    selectedDate,
    calendarTimezone,
    mainColor
}) => {

    const onSubmit = useCallback(async () => {

        const user = getUrlParameter('u')
        const lead_source = getUrlParameter('l')
        const lead = getUrlParameter('le')

        toggleStandardLoader(true);
        const data = await _nylas.createEvent({
            start: selectedTime.start,
            end: selectedTime.end,
            companySize,
            companyName,
            url: window.location.href,
            givenName,
            familyName,
            email,
            phone,
            timezone,
            reschedule: consultationId,
            date: selectedDate.date,
            calendarTimezone, 
            lead_source,
            lead,
            user
        })
        toggleStandardLoader(false);

        if(data.success) {
            setAttorney(data.data.user)
            setView('confirmation')
            console.log(data)
        } else {
            if(data.errs) {
                setErrs(data.errs)
            } else {
                if(data.message === 'This time is no longer available, please choose another time slot.') {
                    setView('select time')
                    toggleAlertBS('info', data.message)
                } else if(data.message === 'It looks like you already have a case open with Biz Head Law. Please reach out to your contact directly or email operations@bizheadlaw.com should you have any questions.') {
                    toggleAlertBS('info', data.message)
                } else {
                    toggleAlertBS(true, `It looks like we're experiencing some technical difficulties, please try again.`)
                }
            }
        }

    }, [givenName, familyName, email, phone, selectedTime, setErrs, setView, companySize, setAttorney, consultationId, timezone, selectedDate.date, calendarTimezone, companyName])

    return (
        <div className="archk-calendar-wrapper">
            
            <div className="archk-calendar-header pb-0">
                <h2 style={{color: mainColor ? mainColor : '#FF8D00'}} className=" mb-0 display-3"><i className="fas fa-info-circle text-white mr-2 " /> {titleEnterInfo}</h2>
            </div>

            <div className="archk-calendar-header text-cener position-relative pt-0">

                <button className="btn btn-secondary my-4" onClick={() => setView('select time')} >
                    <i className="fas fa-arrow-left mr-3 cursor-pointer" />
                    Go Back
                </button>

                <h3 style={{color: mainColor ? mainColor : '#FF8D00'}} className="mb-0 ">The time you have selected is: {moment(selectedTime.date).tz(timezone).format('dddd MMMM Do, YYYY - h:mm A')}</h3>
                <p className="text-sm"><small>{getTimezoneName(timezone)}</small></p>


                <div className="mt-3">
                    <h3 className="text-cente mb-0">Click To Finish</h3>
                    <p className="text-sm text-white">Click the orange button below to confirm and lock in your appointment.</p>
                </div>
           
            </div>
    

            <div className="archk-calendar-body">
                <button 
                    onClick={onSubmit} 
                    className="btn btn-primary btn-block btn-lg"
                                        style={{background: mainColor ? mainColor : '#FF8D00'}}
                >
                    <i className="fas fa-check-double mr-2 " /> Confirm Appointment
                </button>

                {/* <p className="text-sm mb-0 mt-4"><small>By submitting, you agree to the <a href="https://www.bizheadlaw.com/terms-and-confitions" target="_blank" rel="noopener noreferrer">TOS</a> and  <a href="https://www.bizheadlaw.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>, and you agree the phone number you provided may be used to be contacted by us (including autodialed, pre-recorded calls or text messages). If you want us to contact you without consenting to these methods of communication, call or email us.</small></p> */}
            </div>
        </div>
    )

}

export default ArchCalendarBookingEnterInfo;