import { Container } from 'reactstrap';
import { useEffect } from 'react';
import ArchkCalendarBooking from '../../../../components/ArchkCalendarBooking2'
import { getUrlParameter } from 'utils/urls';

import Logo from 'assets/img/brand/BizHeadLogoCut.png'

const Book = () => {

    const title = getUrlParameter('title')

    const isIframe = window.self !== window.top;
    const mainColor = getUrlParameter('color')
    const theme = getUrlParameter('theme')
    const titleEnterQualifications = title ? title : 'Book A Call With Biz Head Law'

    useEffect(() => {
        if(isIframe && theme === 'light') document.body.style.background = 'white'
    }, [isIframe, theme])

    if(isIframe) {
        return   (
            <div className="pt-3">
            <ArchkCalendarBooking 
                theme={theme}
                bookingInterval={window.location.href.includes('63910bba19b5c0723a66b301') ? 45 : 30}
                // disabledDays={[6, 7]}
                disabledDays={[]}
                calendarTimezone={'America/New_York'}
                calendarStartTime={6 * 60 * 60}
                calendarEndTime={20 * 60 * 60}
                titleSelectDay="Schedule A Free Consultation"
                titleSelectTime="What Time Do You Want To Book?"
                titleEnterInfo="Confirm Your Selected Time"
                titleConfirmation="All Done! You're Consultation Is Set."
                titleEnterQualifications={titleEnterQualifications}
                mainColor={mainColor ? '#' + mainColor : mainColor}
            />
            </div>
        )
    }

    return (
       <Container className="pt-5">

            <div style={{maxWidth: 360, margin: 'auto', }} className="z-depth-5 border rounded pb-3">
                <div className="text-centr pl-3 pt-3 ml-1">
                    <img src={Logo} style={{width: 225}} alt="Biz Head Law" />
                </div>

                <ArchkCalendarBooking 
                    theme={theme}
                    bookingInterval={window.location.href.includes('63910bba19b5c0723a66b301') ? 45 : 30}
                    // disabledDays={[6, 7]}
                    disabledDays={[]}
                    calendarTimezone={'America/New_York'}
                    calendarStartTime={6 * 60 * 60}
                    calendarEndTime={20 * 60 * 60}
                    titleSelectDay="Schedule A Free Consultation"
                    titleSelectTime="What Time Do You Want To Book?"
                    titleEnterInfo="Confirm Your Selected Time"
                    titleConfirmation="All Done! You're Consultation Is Set."
                    titleEnterQualifications="Book A Call With Biz Head Law"
                />
            </div>

       </Container>
    )
}

export default Book;