import { useState } from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment-timezone';
import { getTimezoneName } from './timezones'
import ModalSelectTimezone from './ModalSelectTimezone';

const ArchCalendarBookingSelectDay = ({setMonth, month, rows, timezone, onSelectDate, titleSelectDay, setTimezone, type, start, mainColor}) => {

    const [showModal, setShowModal] = useState(false);

    return (
        <div className="archk-calendar-wrapper">
                        
            <div className="archk-calendar-header">
    
                <h2 style={{color: mainColor ? mainColor : '#FF8D00'}} className="mb-4 display-3">
                    <i className="fas fa-check-circle text-white mr-2 " />{' '} 
                    {type === 'reschedule' ? 'Reschedule Your Consultation' : titleSelectDay}
                </h2>

                {type === 'reschedule' ? (
                    <p className="text-sm">
                        <small>
                            Your consultation was originally set for:{' '}
                            <span style={{color: mainColor ? mainColor : '#FF8D00'}} className=" d-block">{moment.unix(start).tz(timezone).format('dddd MMM Do, YYYY - h:mmA')} </span>
                            <span className="d-block">{getTimezoneName(timezone)}</span>
                        </small>
                    </p>
                ) : null}
    
                <Row>
                    <Col xs={8} className="align-self-center">
                        <h3 className="mb-0"> Select A Date Below</h3>
                        <p className="mb-0 text-sm">{month.format('MMMM YYYY')}  </p>
                    </Col>
                    <Col xs={4} className="align-self-center text-right">
                        <i style={{background: mainColor ? mainColor : '#FF8D00'}} className="archk-calendar-move  fas fa-angle-left " onClick={() => setMonth(moment(month).subtract(1, 'month'))} />
                        <i style={{background: mainColor ? mainColor : '#FF8D00'}} className="archk-calendar-move  fas fa-angle-right " onClick={() => setMonth(moment(month).add(1, 'month'))} />
                    </Col>
                </Row>
            </div>
    
            <div className="archk-calendar-body">
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Sun</th>
                                <th>Mon</th>
                                <th>Tue</th>
                                <th>Wed</th>
                                <th>Thu</th>
                                <th>Fri</th>
                                <th>Sat</th>
                            </tr>
                        </thead>
    
                        <tbody>
                            {rows.map((row, i) => (
                                <tr key={i}>
                                    {row.dates.map((date, ii) => {
    
                                        const hideText = !date.value
                                        const disabled = date.disabled;
                                        const preventClick = disabled || hideText
    
                                        return (
                                            <td key={ii}>
                                                <button style={{background: !disabled && !preventClick ? mainColor ? mainColor : '#FF8D00' : ''}}  onClick={preventClick ? null : () => onSelectDate(date)} disabled={preventClick} >
                                                    {hideText ? '' : date.text}
                                                </button>
                                            </td>
                                        )
    
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
    
            <div className="archk-calendar-footer">
                <p className="text-sm mb-0">
                    <span className="cursor-pointer" onClick={() => setShowModal(true)}>
                        {getTimezoneName(timezone)}{' '}
                        <i className="fas fa-caret-down text-white ml-2 " />
                    </span>
                </p>
            </div>

            <ModalSelectTimezone 
                showModal={showModal}
                toggleModal={() => setShowModal(!showModal)}
                timezone={timezone} 
                setTimezone={setTimezone}
            />
        </div>
    )
}

export default ArchCalendarBookingSelectDay;